
import Vue from "vue";
import { tableColumnsListCountyValidation } from "./quoteCountyTable";
import { ITableWrapperColumns } from "@/types";
import NoResultsFound from "@/components/NotFound/NoResultsFound.vue";
import {
  quoteCountyValidationMapActions,
  quoteCountyValidationMapGetters
} from "@/store/modules/quoteCountyValidation";
import { cloneDeep } from "lodash";

interface IQuoteValidationData {
  tableActions: any[];
  errorMessage: string;
  isLoading: boolean;
  isAdmin: boolean;
  loading: boolean;
  successMessage: string;
  quoteCountyValidationId: string;
  tableRowActions: any[];
  loadingText: string;
}
interface IActions {
  text: string;
  key: string;
  subItems: { title: string; command: string }[];
}
export default Vue.extend({
  name: "listQuoteValidations",
  components: {
    NoResultsFound
  },
  data(): IQuoteValidationData {
    return {
      successMessage: "",
      errorMessage: "",
      isLoading: false,
      isAdmin: true,
      tableActions: [],
      loading: false,
      quoteCountyValidationId: "",
      loadingText: "",
      tableRowActions: [
        { label: "Edit", value: "edit", faIcon: "edit" },
        {
          label: "Delete",
          value: "delete",
          faIcon: "trash-alt",
          class: "bg-red-400 text-white hover:text-white hover:bg-red-500"
        }
      ]
    };
  },
  methods: {
    ...quoteCountyValidationMapActions([
      "getQuoteCountyValidations",
      "deleteQuoteCountyValidation"
    ]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "createNewCountyValidation":
          this.$router.push(`/admin/quoteCountyValidations/create`);
          break;
        case "quote-county-validations-bulk-activities":
          this.$router
            .push(`/admin/quoteCountyValidations/activities`)
            .catch(() => {});
          break;
      }
    },
    async fetchPageData(queryObject: any): Promise<any> {
      try {
        this.loading = true;
        this.loadingText = "Loading validations. Please wait...";
        this.$emit("scrollTop");
        await this.getQuoteCountyValidations({
          query: { ...queryObject }
        });
      } catch (error) {
        this.$bugSnagClient.notify(error);
      } finally {
        this.loading = false;
        this.loadingText = "";
      }
    },

    async viewCallback(dataReceived: any): Promise<any> {
      const { _id } = dataReceived;
      this.$router.push(`/admin/quoteCountyValidation/${_id}`).catch(() => {});
    },
    //
    async handleResponseAction(payload: any) {
      const { data } = payload;
      switch (payload.name) {
        case "duplicate":
          //do nothing
          break;
        case "delete":
          this.deleteCallback(data._id);
          break;
        case "edit":
          this.$router.push(`/admin/quoteCountyValidation/${data._id}`);
          break;
        default:
          this.errorMessage = "Operation specified is not supported";
      }
    },

    async deleteOneQuoteCountyValidation(): Promise<void> {
      try {
        this.isLoading = true;
        this.loadingText = "Deleting validation. Please wait...";
        await this.deleteQuoteCountyValidation(this.quoteCountyValidationId);
        this.$appNotifySuccess("Validation Successfully Deleted");
        this.$modal.hide("quoteCountyValidationDeleteModal");
      } catch (e) {
        this.errorMessage = e.message;
        this.$bugSnagClient.notify(e);
      } finally {
        this.isLoading = false;
        this.loadingText = "";
      }
    },
    //delete modal
    async deleteCallback(quoteCountyValidationId: any): Promise<void> {
      try {
        this.quoteCountyValidationId = quoteCountyValidationId;
        this.$modal.show("quoteCountyValidationDeleteModal");
      } catch (error) {
        // delete disabled
      }
    }
  },
  computed: {
    ...quoteCountyValidationMapGetters([
      "getQuoteCountyValidationsLinks",
      "getTotalRecords",
      "quoteCountyValidations"
    ]),
    tableColumns(): ITableWrapperColumns {
      return cloneDeep(tableColumnsListCountyValidation);
    },
    topButtonSecondaryItems(): IActions {
      const primary = {
        text: "Actions",
        key: "actions"
      };
      const otherUsersOptions: IActions["subItems"] = [
        {
          title: "All Activities",
          command: "quote-county-validations-bulk-activities"
        }
      ];

      return {
        ...primary,
        subItems: otherUsersOptions
      };
    }
  }
});
